import React from "react";
import {
    Pane,
    Heading,
    majorScale,
    Paragraph,
    Image,
    Link
} from "evergreen-ui";
import MeImage from "../assets/me.jpg";

import ReactPlayer from "react-player";

function App() {
    return (
        <Pane display="flex">
            <Pane
                display="flex"
                width={600}
                minWidth={400}
                marginX={"auto"}
                flexDirection="column"
                alignItems="center"
                padding={majorScale(2)}
            >
                {/** header **/}
                <Pane
                    display="flex"
                    padding={majorScale(4)}
                    width={"100%"}
                    minWidth={300}
                    alignItems="center"
                    border
                    borderRadius={majorScale(1)}
                    backgroundColor="white"
                >
                    <Pane flex={1} marginRight={majorScale(2)}>
                        <Image width={"100%"} maxWidth={majorScale(30)} minWidth={majorScale(17)} src={MeImage} />
                    </Pane>
                    <Pane display="flex" flexDirection="column" alignItems="center" flex={2} minWidth={288}>
                        <Heading marginBottom={majorScale(1)} size={700}>
                            Robert McQueen
            </Heading>
                        <Heading size={500} marginBottom={majorScale(1)}>
                            Graduate Student in Philosophy
            </Heading>
                        <Heading size={400}>Boston College, FY23</Heading>
                        <Paragraph paddingTop={majorScale(1)} textAlign="center" >
                            <b>Main Interests:</b>
                            <br></br>
                            Early Modern Philosophy
			    <br></br>
                            Contemporary Analytic Metaphysics
			</Paragraph>
                    </Pane>
                </Pane>

                <Pane padding={majorScale(1)}>
                    <Paragraph>
                        Shortcuts: <Link href="#about">About</Link> - <Link href="#papers">Papers</Link> - <Link href="#projects">Digital Humanities</Link> - <Link href="#music">Music</Link> - <Link target="_blank" href="https://drive.google.com/file/d/1sYxtDYy54cPWsh51CWn6ysyBgTWtiZio/view?usp=sharing">CV</Link>
                    </Paragraph>
                </Pane>

                {/** about me **/}
                <Pane id="about" marginBottom={majorScale(4)} backgroundColor="white" padding={majorScale(4)} border borderRadius={majorScale(1)}>
                    <Pane
                        borderBottom
                        paddingBottom={majorScale(1)}
                        marginBottom={majorScale(1)}
                    >
                        <Heading size={700}>Hello there!</Heading>
                    </Pane>
                    <Paragraph marginBottom={majorScale(2)}>
                        I am currently a graduate student in Boston College's Master's Philosophy Program. I am seeking to conduct a PhD in early modern philosophy and contemporary analytic metaphysics. Prior to Boston College, I graduated from MIT in 2012 with a degree in Computer Science and worked as a software engineer in San Francisco for almost ten years.
		    </Paragraph>

                    <Paragraph marginBottom={majorScale(2)}>
                        I am motivated generally by questions involving the character of fundamental reality, such as grounding relations (i.e. “in virtue of” structures), the limits of explanatory principles (e.g. the principle of sufficient reason and non-contradiction), and various modes of expression capable of integrating parsimonious fundamentals with diverse, phenomenal plenitudes. I wish to encounter these questions by bringing early modern philosophy (esp. Spinoza and Kant) to bear on contemporary analytic metaphysics, especially on the topics of grounding and causation. For example, I would like to perform an extensive close-reading of Spinoza’s Ethics with a focus on evaluating the transitivity, reflexivity, symmetry, and well-foundedness of various modes of dependence relations (e.g. inherence, causation, conception). I believe this research will be critical for evaluating contemporary metaphysical theories of monism (e.g. Existential, Priority, and Radical).
          </Paragraph>
                </Pane>

                {/** research papers **/}
                <Pane id="papers" marginBottom={majorScale(4)} backgroundColor="white" padding={majorScale(4)} border borderRadius={majorScale(1)}>
                    <Pane
                        borderBottom
                        paddingBottom={majorScale(1)}
                        marginBottom={majorScale(1)}
                    >
                        <Heading size={700}>Current Papers</Heading>
                    </Pane>

                    <Paragraph padding={majorScale(1)}>
                        <Link
                            href="https://drive.google.com/file/d/1ASua9T2Lxi7NprcveaFhae7b8o1x3Far/view?usp=sharing"
                            target="_blank"
                        >
                            <b>From Dualism to Monism: Descartes, Spinoza, and Brute Facts</b>
                        </Link>
                        <br />While Rene Descartes and Baruch de Spinoza begin with similar definitions of substance, they ultimately end up at conflicting metaphysical conclusions. For Descartes, reality is dualistic; for Spinoza, monistic. The task of my paper is to articulate the fundamental methodological differences between Descartes and Spinoza in order to account for their differing metaphysical conclusions.
          </Paragraph>

                    <Paragraph padding={majorScale(1)}>
                        <Link
                            href="https://drive.google.com/file/d/1W4EpJ49n2UjDG-9h2BgOk2roU1Il9Eug/view?usp=sharing"
                            target="_blank"
                        >
                            <b>Kant, Leibniz, and the Simple</b>
                        </Link>
                        <br />In his post-critical writings, Kant claims that his critical philosophy can act as an apology to Leibniz's metaphysics. In this paper, I investigate the claim, narrowing the scope of concordance to Leibniz's monads and Kant's nuomenon.
          </Paragraph>

                    <Paragraph padding={majorScale(1)}>
                        <Link
                            href="https://drive.google.com/file/d/1rwC8MkV7_sYWp5oVVZDZk2p13TE8BIcS/view?usp=sharing"
                            target="_blank"
                        >
                            <b>Augustine and the Death of the Soul in <i>Confessions</i></b>
                        </Link>
                        <br />An exegesis of what Augustine means by the "death of the soul" within the <i>Confessions.</i>
                    </Paragraph>

                    <Paragraph padding={majorScale(1)}>
                        <Link
                            href="https://drive.google.com/file/d/1nTp4-NB2HVB2ZY61IuEacKtot0I-M5lf/view?usp=sharing"
                            target="_blank"
                        >
                            <b>On Duns Scotus' Univocity of Being: Influence of Henry of Ghent</b>
                        </Link>
                        <br />This paper investigates how Henry of Ghent influences Duns Scotus' concept of the Univocity of Being
		    </Paragraph>



                    <Pane
                        borderBottom
                        marginTop={majorScale(4)}
                        paddingBottom={majorScale(1)}
                        marginBottom={majorScale(1)}
                    >
                        <Heading size={700}>Conference Papers</Heading>
                    </Pane>

                    <Paragraph padding={majorScale(1)}>
                        <Link
                            href="https://drive.google.com/file/d/1FgIWUb1UV0W_TZB2NTR7JuPXQlp8FHQJ/view?usp=sharing"
                            target="_blank"
                        >
                            <b>John Scotus Eriugena: Immanent Theophany and Transcendental God</b>
                        </Link>
                        {" "} Given at 2022 Villanova Graduate Conference: God and the Unconditional. I present Eriugena's concept of Theophany in the context of his four divisions of nature.
          </Paragraph>

                    <Paragraph padding={majorScale(1)}>
                        <Link
                            href="https://drive.google.com/file/d/1GvSfWOxWglhHK6FTILSNfwW5PC7g3t2M/view?usp=sharing"
                            target="_blank"
                        >
                            <b>Christian Mysticism: Is it Nonsense? Roundtable</b>
                        </Link>
                        {" "} I helped organize a roundtable with two other BC Graduate Students at the Northeast Philosophy of Religion Colloquium (NEPRC) at Fordham University. We led a discussion on whether a series of mystics (including Pseudo-Dionysius, Eriugena, Eckhart, and more) contribute a positive and intelligible intimation of transcendence.
          </Paragraph>

                    <Paragraph padding={majorScale(1)}>
                        <Link
                            href="https://drive.google.com/file/d/1WFgNR30Yz66euyr11JMIKRMbytvsoMKk/view?usp=sharing"
                            target="_blank"
                        >
                            <b>Pico Della Mirandola: On Uniqueness and Solidarity of Human Nature</b>
                        </Link>
                        {" "} Given at 2022 Boston College Graduate Student Conference. I discuss the unique indeterminate nature of man according to Pico Della Mirandola, and how such an indeterminate nature must answer to a solidarity with God the Creator          </Paragraph>
                </Pane>

                {/** about me **/}
                <Pane id="projects" marginBottom={majorScale(4)} backgroundColor="white" padding={majorScale(4)} border borderRadius={majorScale(1)}>
                    <Pane
                        borderBottom
                        paddingBottom={majorScale(1)}
                        marginBottom={majorScale(1)}
                    >
                        <Heading size={700}>Digital Humanities Projects</Heading>
                    </Pane>

                    <Paragraph marginBottom={majorScale(1)}>
                        Part of my research involves working within the Digital Humanities. I worked as a software engineer for ten years before entering academia.
		    </Paragraph>

                    <Paragraph padding={majorScale(1)}>
                        <Link
                            href="https://spinoza2.herokuapp.com/"
                            target="_blank"
                        >
                            <b>Spinoza's Ethics Digital Site</b>
                        </Link>  <i>CONTRIBUTER</i>
                        <br /><i>in collaboration with Jean-Luc Solère and Boston College</i>
                        <br />A digital version of Spinoza's Ethics with visualizations and mapping tools.
		    </Paragraph>

                    <Paragraph padding={majorScale(1)}>
                        <Link
                            href="http://arendtindex.com"
                            target="_blank"
                        >
                            <b>The Hannah Arendt Index</b>
                        </Link> <i>CREATOR</i>
                        <br /><i>in collaboration with Library of Congress</i>
                        <br />The Hannah Arendt Index provides a full-text search to all documents within the Hannah Arendt Library of Congress collection. It uses the Library of Congress API as well as Google Cloud Vision API for Machine Learning OCR recognition.
		    </Paragraph>

                    <Paragraph padding={majorScale(1)}>
                        <Link
                            href="https://waltwhitman.herokuapp.com/"
                            target="_blank"
                        >
                            <b>The Walt Whitman Index</b>
                        </Link> <i>CREATOR</i>
                        <br /><i>in collaboration with University of Nebraska-Lincoln</i>
                        <br />The Walt Whitman index provides a full-text search to all documents within the Library of Congress collection. The code to build this project is derived from the code written for the Hannah Arendt Index.
		    </Paragraph>

                </Pane>

                <Pane width="100%" id="music" marginBottom={majorScale(4)} backgroundColor="white" padding={majorScale(4)} border borderRadius={majorScale(1)}>
                    <Pane
                        borderBottom
                        paddingBottom={majorScale(1)}
                        marginBottom={majorScale(1)}
                    >
                        <Heading size={700}>Music</Heading>
                    </Pane>

                    <Paragraph marginBottom={majorScale(1)}>In 2017, I took a year off to solely learn how to produce music. Here are some songs I have made</Paragraph>



                    <Pane marginBottom={majorScale(4)} border padding={majorScale(1)}>
                        {/*
            //@ts-ignore */}
                        {<ReactPlayer width="100%" height={150} url='https://soundcloud.com/basscadet/bass-cadet-x-just-dust-summer-love-ft-dave-fontenot' />}
                        <Heading marginTop={majorScale(1)}>Summer Love</Heading>
                        <Paragraph>Summer Love is my favorte track I've worked on. My friend, Bass Cadet, and I spent a weekend in LA putting this track together under the sun with a mutual friend who provided the vocals and lyrics.
          </Paragraph>
                    </Pane>

                    <Pane marginBottom={majorScale(4)} border padding={majorScale(1)}>
                        {/*
            //@ts-ignore */}
                        {<ReactPlayer width="100%" height={200} url='https://soundcloud.com/just-dust/lilo' />}
                        <Heading marginTop={majorScale(1)}>The Japanese House- Lilo (Just Dust Remix)</Heading>
                        <Paragraph>This is my favorite remix. It is a bit dark, but it has a momentum that just keeps going. The low-end mix and groove works very well.</Paragraph>
                    </Pane>

                    <Pane marginBottom={majorScale(4)} border padding={majorScale(1)}>
                        {/*
            //@ts-ignore */}
                        {<ReactPlayer width="100%" height={200} url='https://soundcloud.com/mcqtunes/pound-for-pound-original-mix' />}
                        <Heading marginTop={majorScale(1)}>Pound for Pound (Original Mix)</Heading>
                        <Paragraph>I produced a series of tracks under the alias McQ. My goal was to get signed to a record label.</Paragraph>
                    </Pane>

                    <Pane marginBottom={majorScale(4)} border padding={majorScale(1)}>
                        {/*
            //@ts-ignore */}
                        {<ReactPlayer width="100%" height={200} url="https://soundcloud.com/basscadet/bass-cadet-x-just-dust-california-love-remix" />}
                        <Heading marginTop={majorScale(1)}>California Love Remix</Heading>
                        <Paragraph>A very fun track I collabed with Bass Cadet. We received a lot of great recognition for it.</Paragraph>
                    </Pane>




                </Pane>
            </Pane>
        </Pane >
    );
}

export default App;
